import { Role } from '@/models/Role';
import { Component, Prop, PropSync, Vue } from 'vue-property-decorator';

@Component<RoleSelector>({})
export default class RoleSelector extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  @PropSync('roles')
  protected internalRoles!: Role[];

  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */

  protected isLoading = false;

  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
*/

  // #endregion

  // #region Class methods
  // #endregion

  protected handleValueChanged(roleId: string, value: boolean): void {
    console.log(roleId);
    this.$emit('valueChanged', { roleId, value });
  }

  protected checkboxLabelClasses(role: Role): string {
    return role.value ? 'font-semibold text-primary-500' : 'font-normal text-black-400';
  }

  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get allRolesSelected(): boolean {
    return this.internalRoles.every((role) => role.value);
  }

  protected get toggleText(): string {
    return this.allRolesSelected ? 'deselecteren' : 'selecteren';
  }

  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
