import { Component, Vue } from 'vue-property-decorator';
import { Permission as PermissionModel, UpdatePermissionPayload } from '@/models/Permission';
import RoleSelector from '@/components/RoleSelector/RoleSelector.vue';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Role } from '@/models/Role';

@Component<Permission>({
  components: {
    RoleSelector,
  },
})

export default class Permission extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */

  protected isLoading = false;

  protected isUpdating = false;

  protected isEditingPermission = false;

  protected isSavingRole = false;

  protected savedRoleSuccesfully = false;

  protected roles: Role[] = [];

  protected permission: PermissionModel | null = null;

  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  protected async mounted() {
    this.isLoading = true;
    await this.fetchPermission();
    await this.fetchRoles();
    this.emitBreadcrumb();
    this.isLoading = false;
  }
  // #endregion

  // #region Class methods

  protected handleValueChanged(event: { roleId: string, value: boolean }): void {
    if (event.value === true) {
      this.attachToRole(event.roleId);
    } else {
      this.detachFromRole(event.roleId);
    }
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Permissies', path: '/instellingen/permissies' },
          { name: this.permission?.name || '' },
        ],
      });
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */
  protected async fetchPermission(): Promise<void> {
    const permission = await new PermissionModel()
      .include(['roles'])
      .find(this.$route.params.id)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (permission) {
      this.permission = permission;
    }
  }

  protected async fetchRoles(): Promise<void> {
    const roles = await new Role()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (roles) {
      this.permission?.roles?.forEach((indexedRole: Role) => {
        const activeRole = roles.find((role: Role) => indexedRole.id === role.id);
        if (activeRole) {
          activeRole.value = true;
        }
      });
      this.roles = roles;
    }
  }

  protected async attachToRole(roleId: string): Promise<void> {
    this.isSavingRole = true;
    try {
      await this.permission?.attachPermissionToRole(roleId);
      this.savedRoleSuccesfully = true;
      setTimeout(() => {
        this.savedRoleSuccesfully = false;
      }, 2000);
    } catch (error) {
      ErrorHandler.network(error);
    } finally {
      this.isSavingRole = false;
    }
  }

  protected async detachFromRole(roleId: string): Promise<void> {
    this.isSavingRole = true;
    try {
      await this.permission?.detachPermissionFromRole(roleId);
      this.savedRoleSuccesfully = true;
      setTimeout(() => {
        this.savedRoleSuccesfully = false;
      }, 2000);
    } catch (error) {
      ErrorHandler.network(error);
    } finally {
      this.isSavingRole = false;
    }
  }

  protected async update(): Promise<void> {
    if (! this.permission) {
      return;
    }

    this.isUpdating = true;

    const payload = this.updatePayload;

    const permission = await this.permission
      .update(payload)
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });

    if (permission) {
      this.permission = permission;
      this.isUpdating = false;
      this.isEditingPermission = false;
    }
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get updatePayload(): UpdatePermissionPayload {
    return {
      name: this.permission?.name || '',
      slug: this.permission?.slug || '',
      description: this.permission?.description || '',
    };
  }

  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
