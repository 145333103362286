var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    _vm._g(_vm._b({}, "div", _vm.$attrs, false), _vm.$listeners),
    [
      _c(
        "div",
        [
          _c(
            "v-layout",
            { staticClass: "pb-16", attrs: { wrap: "" } },
            [
              _vm.internalRoles.length
                ? [
                    _vm._l(_vm.internalRoles, function(role) {
                      return [
                        _c(
                          "v-flex",
                          { key: role.id, attrs: { xs12: "", md4: "" } },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                "hide-details": "",
                                name: "useAllowance",
                                color: "#a09b1b"
                              },
                              on: {
                                change: function($event) {
                                  return _vm.handleValueChanged(role.id, $event)
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "label",
                                    fn: function() {
                                      return [
                                        _c("div", { staticClass: "ml-8" }, [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "tw-mb-0",
                                              class: _vm.checkboxLabelClasses(
                                                role
                                              )
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(role.name) + " "
                                              )
                                            ]
                                          )
                                        ])
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: role.value,
                                callback: function($$v) {
                                  _vm.$set(role, "value", $$v)
                                },
                                expression: "role.value"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    })
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }