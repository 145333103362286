var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { attrs: { "grid-list-lg": "" } },
        [
          _vm.isLoading
            ? _c("LoaderCard", { attrs: { flat: "", type: "spinner--center" } })
            : _vm._e(),
          !_vm.isLoading && _vm.permission
            ? _c(
                "v-layout",
                { attrs: { row: "", wrap: "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { sm12: "", md5: "", lg4: "", xl3: "" } },
                    [
                      _c(
                        "div",
                        { staticClass: "elementPanel profile" },
                        [
                          !_vm.isLoading
                            ? [
                                _c(
                                  "v-layout",
                                  {
                                    staticClass: "profileContainer",
                                    class: { editing: _vm.isEditingPermission },
                                    attrs: { row: "", wrap: "" }
                                  },
                                  [
                                    !_vm.isEditingPermission
                                      ? _c(
                                          "div",
                                          { staticClass: "iconContainer" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "iconContainer__edit"
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "edit-icon",
                                                    on: {
                                                      click: function($event) {
                                                        _vm.isEditingPermission = true
                                                      }
                                                    }
                                                  },
                                                  [_vm._v("edit")]
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isEditingPermission
                                      ? _c(
                                          "div",
                                          { staticClass: "iconContainer" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass: "cancel-icon",
                                                on: {
                                                  click: function($event) {
                                                    _vm.isEditingPermission = false
                                                    _vm.fetchPermission()
                                                  }
                                                }
                                              },
                                              [_vm._v(" cancel ")]
                                            ),
                                            !_vm.isUpdating
                                              ? _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "save-icon",
                                                    on: { click: _vm.update }
                                                  },
                                                  [_vm._v("save")]
                                                )
                                              : _vm._e(),
                                            _vm.isUpdating
                                              ? _c("LoaderCard", {
                                                  staticClass:
                                                    "spinner--inline",
                                                  attrs: {
                                                    type: "spinner--small"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.permission
                                      ? _c(
                                          "v-flex",
                                          { attrs: { "mt-3": "" } },
                                          [
                                            _vm.isEditingPermission
                                              ? [
                                                  _c("v-text-field", {
                                                    staticClass: "mb-16",
                                                    attrs: { label: "Naam" },
                                                    model: {
                                                      value:
                                                        _vm.permission.name,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.permission,
                                                          "name",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "permission.name"
                                                    }
                                                  }),
                                                  _c("v-text-field", {
                                                    staticClass: "mb-16",
                                                    attrs: { label: "Slug" },
                                                    model: {
                                                      value:
                                                        _vm.permission.slug,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.permission,
                                                          "slug",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "permission.slug"
                                                    }
                                                  }),
                                                  _c("v-textarea", {
                                                    attrs: {
                                                      label: "Beschrijving"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.permission
                                                          .description,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.permission,
                                                          "description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "permission.description"
                                                    }
                                                  })
                                                ]
                                              : _vm._e(),
                                            !_vm.isEditingPermission
                                              ? [
                                                  _c(
                                                    "h1",
                                                    {
                                                      staticClass:
                                                        "tw-font-normal tw-mb-16"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.permission.name
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c("v-text-field", {
                                                    staticClass: "mb-16",
                                                    attrs: {
                                                      label: "Slug",
                                                      disabled: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.permission.slug,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.permission,
                                                          "slug",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "permission.slug"
                                                    }
                                                  }),
                                                  _c("v-textarea", {
                                                    attrs: {
                                                      label: "Beschrijving",
                                                      disabled: ""
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.permission
                                                          .description,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.permission,
                                                          "description",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "permission.description"
                                                    }
                                                  })
                                                ]
                                              : _vm._e()
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  ),
                  _c(
                    "v-flex",
                    { attrs: { sm12: "", md7: "", lg8: "", xl9: "" } },
                    [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass:
                                "tw-relative tw-bg-white tw-mt-8 tw-p-16"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "tw-flex tw-justify-between" },
                                [
                                  _c("h2", { staticClass: "elementSubTitle" }, [
                                    _vm._v("Rollen")
                                  ]),
                                  _vm.isSavingRole
                                    ? [
                                        _c("LoaderCard", {
                                          attrs: {
                                            flat: "",
                                            type: "spinner--small",
                                            height: "20"
                                          }
                                        })
                                      ]
                                    : _vm._e(),
                                  _vm.savedRoleSuccesfully && !_vm.isSavingRole
                                    ? [
                                        _c(
                                          "div",
                                          { staticClass: "tw-flex" },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "fade-in-bottom tw-text-success-600"
                                              },
                                              [_vm._v("mdi-check")]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "fade-in-bottom tw-text-success-600 tw-self-center tw-font-bold"
                                              },
                                              [_vm._v("Opgeslagen")]
                                            )
                                          ],
                                          1
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _c(
                                "div",
                                { staticClass: "tw-full" },
                                [
                                  _c("RoleSelector", {
                                    attrs: { roles: _vm.roles },
                                    on: {
                                      valueChanged: function($event) {
                                        return _vm.handleValueChanged($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }